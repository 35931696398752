import Dasha from "../assets/images/reviews/dasha.jpeg";
import Sofie from "../assets/images/reviews/sofie.jpg";
import Viviane from "../assets/images/reviews/viviane.jpg";
import Angelo from "../assets/images/reviews/angelo.jpg";
import Darryn from "../assets/images/reviews/darryn.jpg";
import Sam from "../assets/images/reviews/sam.webp";

import StarRate from "../assets/images/5starRate.png";

export const testimonials = [
  {
    testiImg: Dasha,
    testiTitle: "DASHA",
    testiDesi: "Mirahiy",
    testiDesc:
      "Never thought about coaching as part of the growth path, I was wrong. Thank you Ev for all your energy and professional support in this journey.",
    testiRate: StarRate,
  },
  {
    testiImg: Sofie,
    testiTitle: "SOFIE",
    testiDesi: "Studio Lou by S.",
    testiDesc:
      "Every business owner should have an Eve in their life. I’m lucky to have met her!",
    testiRate: StarRate,
  },
  {
    testiImg: Viviane,
    testiTitle: "VIVIANE VAN DYCK",
    testiDesi: "Author",
    testiDesc:
      "Working with Eve is like taking the express train from the start of your journey as a business owner and being neatly dropped off where you could only dream you would be.",
    testiRate: StarRate,
  },
  {
    testiImg: Angelo,
    testiTitle: "ANGELO",
    testiDesi: "Engie",
    testiDesc:
      "De nouveaux skills en storytelling avec une formatrice pleine d'énergie!",
    testiRate: StarRate,
  },
  {
    testiImg: Darryn,
    testiTitle: "DARRYN",
    testiDesi: "Ministry of Justice (UK)",
    testiDesc:
      "If you are wanting to get people to connect with you, listen to what you have to say, work with Eve.",
    testiRate: StarRate,
  },
  {
    testiImg: Sam,
    testiTitle: "SAM",
    testiDesi: "Davemullenwines",
    testiDesc:
      "It's incredible how much value I got out of our conversations. Looking back now, it's been a rollercoaster of a year, and still we made it through perfectly fine.",
    testiRate: StarRate,
  },
];
